import React, { Component } from 'react';
import { HashRouter, BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { isAuthenticated } from './services/Auth'
const loading = () => <>
  <div className="d-flex justify-content-center">
    <div className="spinner-grow text-success" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
</>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login/login'));
const Forgot = React.lazy(() => import('./views/Pages/Forgot/forgot'));
const Register = React.lazy(() => import('./views/Pages/Register/register'));
const RegisterCompany = React.lazy(() => import('./views/Pages/RegisterCompany/registerCompany'));
const Redefinition = React.lazy(() => import('./views/Pages/Redefinition/redefinition'));
class App extends Component {
render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                <Route exact path="/" render={props => <Login {...props} />} />
                <Route path="/forgot/:token" render={props => <Redefinition {...props} />} />
                <Route exact path="/forgot" name="Forgot Page" render={props => <Forgot {...props} />} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                <Route exact path="/registercompany" name="Register Page" render={props => <RegisterCompany {...props} />} />
                <Route path="/" name="Home" render={props => isAuthenticated() ? <DefaultLayout {...props} /> : <Redirect to={{ pathname: "/login" }} />} />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
