
const INITIAL_STATE = {
  id_company: '',
  name_company:''
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case 'SET_ID_COMPANY':
      return { ...state, id_company: payload.id, name_company:payload.name }
    default:
      return state
  }
};
