import Api from './Api'
import { store } from '../store/store';
export const isAuthenticated = () => localStorage.getItem('token') !== null;
export const getToken = () => localStorage.getItem('token');
export const getUserId = () =>  localStorage.getItem('user');
// export const setUserId = () =>  localStorage.setItem('user');
export const setToken = token => localStorage.setItem('token', token)

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('persist:root');
  window.location.href = "/login";
};

export const Authorization = `{ headers: { Authorization: 'Bearer ${getToken()}' } }`;

export const setUser = async () => {
  const userData = await Api.get('/api/user', { headers: { Authorization: `Bearer ${getToken()}` }});
  store.dispatch({
    type: 'PROFILE',
    payload: {
      email: userData?.data?.email,
      id_user: userData?.data?.id,
      userName: userData?.data?.name,
      userDoc: userData?.data?.document,
      whats: userData?.data?.customer?.whats,
      phone: userData?.data?.customer?.phone,
      entryData: userData?.data?.created_at?.date,
      image: userData?.data?.customer?.img,
      userStatus: userData?.data?.status_label,
      genderUser: userData?.data?.customer?.gender_label,
      birthData: userData?.data?.customer?.birth,
      is_pf: userData?.data?.customer?.is_pf,
      address: userData?.data?.address,
      role: userData?.data?.role,
      company: userData?.data?.company
    }
  });
}
