import { combineReducers } from 'redux'
import profileData from './profile/reducers';
import config from './Config/reducers';

const Reducer = combineReducers({
  profileData, config
});

export default Reducer;

